/* eslint-disable no-use-before-define */
/* pre */

import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { useRoleAuthorization } from '../../hooks/useRoleAuthorization';
import { useUserState } from '../../context/UserContext';
import { useHttpRequest } from '../../hooks/useHttpRequest';

// styles
import useStyles from './styles';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';
import Rightbar from '../Rightbar/Rightbar';

// pages
import AccountChangePassword from '../../pages/account/ChangePassword';
import MaterialRequestList from '../../pages/material-request/MaterialRequest';
import MaterialRequestNew from '../../pages/material-request/NewOrder';
import MaterialRequestCard from '../../pages/material-request/OrderView';
import MaterialList from '../../pages/material/ManageMaterial';
import MaterialCard from '../../pages/material/MaterialCard';
import ProjectList from '../../pages/project/ManageProjects';
import ProjectCard from '../../pages/project/ProjectCard';
import UserList from '../../pages/user/ManageUsers';
import UserCard from '../../pages/user/UserCard';
import ServiceClaimList from '../../pages/service-claim/ManageServiceClaims';
import ServiceClaimCard from '../../pages/service-claim/ServiceClaimCard';
import ServiceClaimEdit from '../../pages/service-claim/ServiceClaimEdit';
import BracketList from '../../pages/bracket/BracketList';
import BracketCard from '../../pages/bracket/BracketCard';
import BracketQualityCheck from '../../pages/bracket/BracketQualityCheck';
import BracketRequestList from '../../pages/bracket-request/BracketRequestList';
import BracketRequestCard from '../../pages/bracket-request/BracketRequestCard';
import CustomerExistingAircon from '../../pages/customer-existing-aircon/CustomerExistingAircon';
import CustomerExistingAirconCard from '../../pages/customer-existing-aircon/CustomerExistingAirconCard';
import BracketBatchList from '../../pages/bracket-batch/BracketBatchList';
import BracketBatchCard from '../../pages/bracket-batch/BracketBatchCard';
import ServiceClaimMatrixList from '../../pages/service-claim-matrix/ServiceClaimMatrixList';
import ServiceClaimMatrixCard from '../../pages/service-claim-matrix/ServiceClaimMatrixCard';
import TwoFASetup from '../../pages/login/TwoFASetup';
import TwoFAVerification from '../../pages/login/TwoFAVerification';
import TaskTrackingList from '../../pages/task-tracking/TaskTrackingList';
import TaskTrackingCard from '../../pages/task-tracking/TaskTrackingCard';
import RoleList from '../../pages/role/RoleList';
import RoleCard from '../../pages/role/RoleCard';
import ProjectRequestList from '../../pages/project-request/ProjectRequestList';
import ProjectRequestCard from '../../pages/project-request/ProjectRequestCard';
import PayServiceClaim from '../../pages/pay-service-claim/PayServiceClaim';
import ServiceClaimReportList from '../../pages/service-claim-report/ServiceClaimReportList';
import ServiceClaimReportCard from '../../pages/service-claim-report/ServiceClaimReportCard';
import InstallationClaimList from '../../pages/installation-claim/ManageInstallationClaims';
import InstallationClaimCard from '../../pages/installation-claim/InstallationClaimCard';
import InstallationClaimEdit from '../../pages/installation-claim/InstallationClaimEdit';
import InstallationClaimMatrixList from '../../pages/installation-claim-matrix/InstallationClaimMatrixList';
import InstallationClaimMatrixCard from '../../pages/installation-claim-matrix/InstallationClaimMatrixCard';
import InstallationClaimReportList from '../../pages/installation-claim-report/InstallationClaimReportList';
import InstallationClaimReportCard from '../../pages/installation-claim-report/InstallationClaimReportCard';
import FnbEventBookingList from '../../pages/fnb-event-booking/FnbEventBookingList';
import FnbEventBookingCard from '../../pages/fnb-event-booking/FnbEventBookingCard';
import FnbEventMenuList from '../../pages/fnb-event-menu/FnbEventMenuList';
import FnbEventMenuCard from '../../pages/fnb-event-menu/FnbEventMenuCard';
import FnbEventBookingCalendar from '../../pages/fnb-event-booking/FnbEventBookingCalendar';
import FnbFoodList from '../../pages/fnb-food/FnbFoodList';
import FnbFoodCard from '../../pages/fnb-food/FnbFoodCard';
import FnbStoreList from '../../pages/fnb-store/FnbStoreList';
import FnbStoreCard from '../../pages/fnb-store/FnbStoreCard';
import FnbKitckenDashboardRedirect from '../../pages/fnb-kitcken-dashboard-redirect/FnbKitckenDashboardRedirect';
import FnbOrderManagement from '../../pages/fnb-order/FnbOrderManagement';
import FnbOrderCard from '../../pages/fnb-order/FnbOrderCard';
import FnbStoreEventList from '../../pages/fnb-store-event/FnbStoreEventList';
import FnbStoreEventCard from '../../pages/fnb-store-event/FnbStoreEventCard';
import FnbReport from '../../pages/fnb-report/FnbReport';
import MachineCompanyList from '../../pages/machine-company/MachineCompanyList';
import MachineCompanyCard from '../../pages/machine-company/MachineCompanyCard';
import MachineStoreList from '../../pages/machine-store/MachineStoreList';
import MachineStoreCard from '../../pages/machine-store/MachineStoreCard';
import MachineServiceJobList from '../../pages/machine-service-job/MachineServiceJobList';
import MachineServiceJobCard from '../../pages/machine-service-job/MachineServiceJobCard';
import GBBookingList from '../../pages/group-buy-booking/BookingList';
import GBBookingCard from '../../pages/group-buy-booking/BookingCard';
import GBCalendarList from '../../pages/group-buy-calendar/CalendarList';
import GBCalendarCard from '../../pages/group-buy-calendar/CalendarCard';
import GBAttribute from '../../pages/group-buy-attribute/Attribute';
import AirconSubscriptionAttribute from '../../pages/aircon-subscription-attribute/Attribute';
import CompanyList from '../../pages/company/CompanyList';
import FnbPresetList from '../../pages/fnb-preset/FnbPresetList';
import ActivityLogList from '../../pages/activity-log/ActivityLogList';
import FnbVoucherSetupList from '../../pages/fnb-voucher-setup/FnbVoucherSetupList';
import FnbVoucherSetupCard from '../../pages/fnb-voucher-setup/FnbVoucherSetupCard';
import AirconSubscriptionMatrixList from '../../pages/aircon-subscription-matrix/AirconSubscriptionMatrixList';
import AirconSubscriptionMatrixCard from '../../pages/aircon-subscription-matrix/AirconSubscriptionMatrixCard';
import AirconSubscriptionList from '../../pages/aircon-subscription/AirconSubscriptionList';
import FnbSupplierOrderCard from '../../pages/fnb-supplier/FnbSupplierOrderCard';
import FnbSupplierOrderList from '../../pages/fnb-supplier/FnbSupplierOrderList';
import FnbSupplierList from '../../pages/fnb-supplier/SupplierList';
import SupplierListCard from '../../pages/fnb-supplier/SupplierListCard';

function Layout(props) {
  var classes = useStyles();
  const { userInfo } = useUserState();
  const { auth } = useRoleAuthorization();

  const { sendRequest: getUserInfoAPI } = useHttpRequest();

  useEffect(() => {
    getUserInfoAPI("/v1/user/check-two-fa-status-for-redirect");
  }, []);

  const navigateByUser = () => {
    if (userInfo?.defaultUrl) {
      if (userInfo?.defaultUrl != "") {
        return <Redirect to={userInfo?.defaultUrl} />;
      } else {
        return <Redirect to="/app/dashboard" />;
      }
    }
  };

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div className={"main"}>
          {/* <div className={classes.fakeToolbar} /> */}
          <Switch>
            <Route exact path="/app/account" render={() => navigateByUser()} />
            <Route exact path="/app/account/change-password" component={AccountChangePassword} />

            <Route exact path="/app/material-request" render={() => <Redirect to="/app/material-request/list" />} />
            <Route exact path="/app/material-request/list" component={MaterialRequestList} />
            <Route exact path="/app/material-request/new" component={MaterialRequestNew} />
            <Route exact path="/app/material-request/card/:orderCode" component={MaterialRequestCard} />

            <Route exact path="/app/material" render={() => <Redirect to="/app/material/list" />} />
            <Route exact path="/app/material/list" component={MaterialList} />
            <Route exact path="/app/material/new" component={MaterialCard} />
            <Route exact path="/app/material/card/:itemId" component={MaterialCard} />

            <Route exact path="/app/project" render={() => <Redirect to="/app/project/list" />} />
            <Route exact path="/app/project/list" component={ProjectList} />
            <Route exact path="/app/project/card" component={ProjectCard} />
            <Route exact path="/app/project/card/:projectId" component={ProjectCard} />

            <Route exact path="/app/user" render={() => <Redirect to="/app/user/list" />} />
            <Route exact path="/app/user/list" component={UserList} />
            <Route exact path="/app/user/card" component={UserCard} />
            <Route exact path="/app/user/card/:userId" component={UserCard} />

            <Route exact path="/app/service-claim" render={() => <Redirect to="/app/service-claim/list" />} />
            <Route exact path="/app/service-claim/list" component={ServiceClaimList} />
            <Route exact path="/app/service-claim/card" component={ServiceClaimCard} />
            <Route exact path="/app/service-claim/edit/:invoiceNo" component={ServiceClaimEdit} />
            <Route exact path="/app/service-claim/pay" component={PayServiceClaim} />

            <Route exact path="/app/brackets" render={() => <Redirect to="/app/brackets/list" />} />
            <Route exact path="/app/bracket/list" component={BracketList} />
            <Route exact path="/app/bracket/card" component={BracketCard} />
            <Route exact path="/app/bracket/card/:serialNo" component={BracketCard} />
            <Route exact path="/app/bracket/quality-check" component={BracketQualityCheck} />

            <Route exact path="/app/bracket-request" render={() => <Redirect to="/app/bracket-request/list" />} />
            <Route exact path="/app/bracket-request/list" component={BracketRequestList} />
            <Route exact path="/app/bracket-request/card" component={BracketRequestCard} />
            <Route exact path="/app/bracket-request/card/:pkrNo" component={BracketRequestCard} />

            <Route exact path="/app/group-buy-booking" render={() => <Redirect to="/app/group-buy-booking/list" />} />
            <Route exact path="/app/group-buy-booking/list" component={GBBookingList} />
            <Route exact path="/app/group-buy-booking/card" component={GBBookingCard} />
            <Route exact path="/app/group-buy-booking/card/:id" component={GBBookingCard} />

            <Route exact path="/app/group-buy-calendar" render={() => <Redirect to="/app/group-buy-calendar/list" />} />
            <Route exact path="/app/group-buy-calendar/list" component={GBCalendarList} />
            <Route exact path="/app/group-buy-calendar/card" component={GBCalendarCard} />
            <Route exact path="/app/group-buy-calendar/card/:id" component={GBCalendarCard} />

            <Route exact path="/app/group-buy-attribute" component={GBAttribute} />

            <Route exact path="/app/customer-existing-aircon" render={() => <Redirect to="/app/customer-existing-aircon/list" />} />
            <Route exact path="/app/customer-existing-aircon/list" component={CustomerExistingAircon} />
            <Route exact path="/app/customer-existing-aircon/card" component={CustomerExistingAirconCard} />
            <Route exact path="/app/customer-existing-aircon/card/:salesOrderNo" component={CustomerExistingAirconCard} />

            <Route exact path="/app/bracket-batch" render={() => <Redirect to="/app/bracket-batch/list" />} />
            <Route exact path="/app/bracket-batch/list" component={BracketBatchList} />
            <Route exact path="/app/bracket-batch/card" component={BracketBatchCard} />

            <Route exact path="/app/service-claim-matrix" render={() => <Redirect to="/app/service-claim-matrix/list" />} />
            <Route exact path="/app/service-claim-matrix/list" component={ServiceClaimMatrixList} />
            <Route exact path="/app/service-claim-matrix/card" component={ServiceClaimMatrixCard} />
            <Route exact path="/app/service-claim-matrix/card/:matrixId" component={ServiceClaimMatrixCard} />

            <Route exact path="/app/two-fa-setup" component={TwoFASetup}/>
            <Route exact path="/app/two-fa-verification" component={TwoFAVerification}/>

            <Route exact path="/app/task-tracking" render={() => <Redirect to="/app/task-tracking/list" />} />
            <Route exact path="/app/task-tracking/list" component={TaskTrackingList} />
            <Route exact path="/app/task-tracking/card/:staffId/:date/:slot" component={TaskTrackingCard} />

            <Route exact path="/app/role" render={() => <Redirect to="/app/role/list" />} />
            <Route exact path="/app/role/list" component={RoleList} />
            <Route exact path="/app/role/card" component={RoleCard} />
            <Route exact path="/app/role/card/:roleId" component={RoleCard} />

            <Route exact path="/app/project-request" render={() => <Redirect to="/app/project-request/list" />} />
            <Route exact path="/app/project-request/list" component={ProjectRequestList} />
            <Route exact path="/app/project-request/card" component={ProjectRequestCard} />
            <Route exact path="/app/project-request/card/:requestId" component={ProjectRequestCard} />

            <Route exact path="/app/service-claim-report" render={() => <Redirect to="/app/service-claim-report/list" />} />
            <Route exact path="/app/service-claim-report/list" component={ServiceClaimReportList} />
            <Route exact path="/app/service-claim-report/card/:batchNo" component={ServiceClaimReportCard} />

            <Route exact path="/app/installation-claim" render={() => <Redirect to="/app/installation-claim/list" />} />
            <Route exact path="/app/installation-claim/list" component={InstallationClaimList} />
            <Route exact path="/app/installation-claim/card" component={InstallationClaimCard} />
            <Route exact path="/app/installation-claim/edit/:invoiceNo/:team" component={InstallationClaimEdit} />

            <Route exact path="/app/installation-claim-matrix" render={() => <Redirect to="/app/installation-claim-matrix/list" />} />
            <Route exact path="/app/installation-claim-matrix/list" component={InstallationClaimMatrixList} />
            <Route exact path="/app/installation-claim-matrix/card" component={InstallationClaimMatrixCard} />
            <Route exact path="/app/installation-claim-matrix/card/:matrixId" component={InstallationClaimMatrixCard} />

            <Route exact path="/app/installation-claim-report" render={() => <Redirect to="/app/installation-claim-report/list" />} />
            <Route exact path="/app/installation-claim-report/list" component={InstallationClaimReportList} />
            <Route exact path="/app/installation-claim-report/card/:batchNo" component={InstallationClaimReportCard} />

            <Route exact path="/app/fnb-event-booking" render={() => <Redirect to="/app/fnb-event-booking/list" />} />
            <Route exact path="/app/fnb-event-booking/list" component={FnbEventBookingList} />
            <Route exact path="/app/fnb-event-booking/card" component={FnbEventBookingCard} />
            <Route exact path="/app/fnb-event-booking/card/:eventBookingId" component={FnbEventBookingCard} />
            <Route exact path="/app/fnb-event-booking/calendar" component={FnbEventBookingCalendar} />

            <Route exact path="/app/fnb-event-menu" render={() => <Redirect to="/app/fnb-event-menu/list" />} />
            <Route exact path="/app/fnb-event-menu/list" component={FnbEventMenuList} />
            <Route exact path="/app/fnb-event-menu/card" component={FnbEventMenuCard} />
            <Route exact path="/app/fnb-event-menu/card/:eventMenuId" component={FnbEventMenuCard} />

            <Route exact path="/app/fnb-food" render={() => <Redirect to="/app/fnb-food/list" />} />
            <Route exact path="/app/fnb-food/list" component={FnbFoodList} />
            <Route exact path="/app/fnb-food/card" component={FnbFoodCard} />
            <Route exact path="/app/fnb-food/card/:foodId" component={FnbFoodCard} />

            <Route exact path="/app/fnb-preset" component={FnbPresetList} />

            <Route exact path="/app/fnb-store" render={() => <Redirect to="/app/fnb-store/list" />} />
            <Route exact path="/app/fnb-store/list" component={FnbStoreList} />
            <Route exact path="/app/fnb-store/card" component={FnbStoreCard} />
            <Route exact path="/app/fnb-store/card/:storeId" component={FnbStoreCard} />

            <Route exact path="/app/fnb-kitchen-dashboard" render={() => <Redirect to="/app/fnb-kitchen-dashboard/redirect" />} />
            <Route exact path="/app/fnb-kitchen-dashboard/redirect" component={FnbKitckenDashboardRedirect} />

            <Route exact path="/app/fnb-store-event" render={() => <Redirect to="/app/fnb-store-event/list" />} />
            <Route exact path="/app/fnb-store-event/list" component={FnbStoreEventList} />
            <Route exact path="/app/fnb-store-event/card" component={FnbStoreEventCard} />
            <Route exact path="/app/fnb-store-event/card/:eventId" component={FnbStoreEventCard} />

            <Route exact path="/app/fnb-order/" render={() => <Redirect to="/app/fnb-order/" />} />
            <Route exact path="/app/fnb-order/list" component={FnbOrderManagement} />
            <Route exact path="/app/fnb-order/card/:orderId" component={FnbOrderCard} />

            <Route exact path="/app/fnb-voucher-setup" component={FnbVoucherSetupList} />
            <Route exact path="/app/fnb-voucher-setup/:voucherId" component={FnbVoucherSetupCard} />
            <Route exact path="/app/fnb-voucher-setup/new" component={FnbVoucherSetupCard} />

            {/* Fnb Supplier Order */}
            <Route exact path="/app/fnb-supplier-order/new" component={FnbSupplierOrderCard} />
            <Route exact path="/app/fnb-supplier-order/:orderId" component={FnbSupplierOrderCard} />
            <Route exact path="/app/fnb-supplier-order" component={FnbSupplierOrderList} />

            <Route exact path="/app/fnb-supplier-list" component={FnbSupplierList} />
            <Route exact path="/app/fnb-supplier-list/new" component={SupplierListCard} />
            <Route exact path="/app/fnb-supplier-list/:vendorCode" component={SupplierListCard} />

            <Route exact path="/app/fnb-report" render={() => <Redirect to="/app/fnb-report/report" />} />
            <Route exact path="/app/fnb-report/report" component={FnbReport} />

            <Route exact path="/app/machine-company/" render={() => <Redirect to="/app/machine-company/" />} />
            <Route exact path="/app/machine-company/list" component={MachineCompanyList} />
            <Route exact path="/app/machine-company/card" component={MachineCompanyCard} />
            <Route exact path="/app/machine-company/card/:companyId" component={MachineCompanyCard} />

            <Route exact path="/app/machine-store/" render={() => <Redirect to="/app/machine-store/" />} />
            <Route exact path="/app/machine-store/list" component={MachineStoreList} />
            <Route exact path="/app/machine-store/card" component={MachineStoreCard} />
            <Route exact path="/app/machine-store/card/:storeId" component={MachineStoreCard} />

            <Route exact path="/app/machine-service-job/" render={() => <Redirect to="/app/machine-service-job/" />} />
            <Route exact path="/app/machine-service-job/list" component={MachineServiceJobList} />
            <Route exact path="/app/machine-service-job/card" component={MachineServiceJobCard} />
            <Route exact path="/app/machine-service-job/card/:serviceJobId" component={MachineServiceJobCard} />

            <Route exact path="/app/company" component={CompanyList} />

            <Route exact path="/app/activity-log" component={ActivityLogList} />

            <Route exact path="/app/aircon-subscription-attribute" component={AirconSubscriptionAttribute} />

            <Route exact path="/app/aircon-subscription-matrix" render={() => <Redirect to="/app/aircon-subscription-matrix/list" />} />
            <Route exact path="/app/aircon-subscription-matrix/list" component={AirconSubscriptionMatrixList} />
            <Route exact path="/app/aircon-subscription-matrix/card" component={AirconSubscriptionMatrixCard} />
            <Route exact path="/app/aircon-subscription-matrix/card/:id" component={AirconSubscriptionMatrixCard} />

            <Route exact path="/app/aircon-subscription" render={() => <Redirect to="/app/aircon-subscription/list" />} />
            <Route exact path="/app/aircon-subscription/list" component={AirconSubscriptionList} />
          </Switch>
        </div>
        <Rightbar />
      </>
    </div>
  );
}

export default withRouter(Layout);
