import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography, InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useStoreState } from "../../../../context/StoreContext";
import moment from "moment";
import { KeyboardArrowDown } from "@mui/icons-material";
import { validateText } from "../../../../helper/validate-textfield";

export default function OrderPageHeaderView({
  currentOrder,
  formValidation,
  disableForm,
}) {
  const { storeList } = useStoreState();
  const [selectedStore, setSelectedStore] = useState(null);

  const {
    register,
    formState: { errors: formErrors },
    setValue,
  } = formValidation;
  useEffect(() => {
    setValue("remark", currentOrder?.remark || "");
    setValue("pkrNo", currentOrder?.pkrNo || "");
    setValue("storeCode", currentOrder?.storeCode || "");
    setValue("projectManager", currentOrder?.projectManager);
    setValue("project", currentOrder?.project);
    setValue("customerNo", currentOrder?.customerNo);
    setValue("shipToCustomerName", currentOrder?.shipToCustomerName);
    setValue("billToCustomerName", currentOrder?.billToCustomerName);
    setValue("companyCode", currentOrder?.company?.code);
  }, [currentOrder]);

  useEffect(() => {
    if (storeList && currentOrder?.incrementId) {
      setSelectedStore(
        storeList
          .filter((store) => store.code === currentOrder.storeCode)
          .pop(),
      );
    }
  }, [currentOrder, storeList]);

  const handleChangeStore = (newValue) => {
    setValue("storeCode", newValue.code);
    setSelectedStore(newValue);
  };

  function getPkrHelperText() {
    if (formErrors?.pkrNo?.type === "required") {
      return "Document No. cannot be blank.";
    }

    if (formErrors?.pkrNo?.type === "maxLength") {
      return "Maximum is 8 character.";
    }

    if (formErrors?.pkrNo?.type === "pattern") {
      return "Document No. may contain only numbers (0-9).";
    }
  }

  return (
    <>
      {currentOrder?.incrementId && (
        <>
          <Grid item xs={12}>
            <Grid container className={"form"}>
              <Grid item xs={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant={"h2"}>General</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Autocomplete
                          options={storeList}
                          disableClearable={true}
                          getOptionLabel={(store) =>
                            `${store.name}-${store.code}`
                          }
                          onChange={(e, newValue) =>
                            handleChangeStore(newValue)
                          }
                          value={selectedStore}
                          disabled={disableForm}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Store"}
                              error={formErrors["storeCode"]}
                              variant={"outlined"}
                            />
                          )}
                          popupIcon={<KeyboardArrowDown />}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"pkrNo"}
                          label={"Document No. (Enter '00' when not relevent)"}
                          variant={"outlined"}
                          helperText={getPkrHelperText()}
                          {...register("pkrNo", {
                            required: true,
                            maxLength: 8,
                            defaultValue: currentOrder.pkrNo,
                            pattern: /^[0-9]+$/,
                          })}
                          autoComplete={"off"}
                          error={formErrors?.pkrNo}
                          defaultValue={currentOrder.pkrNo}
                          disabled={disableForm}
                          inputProps={{
                            maxLength: 8,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                WSHB-
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"remark"}
                          label={"Remark"}
                          variant={"outlined"}
                          autoComplete={"off"}
                          disabled={disableForm}
                          defaultValue={currentOrder.remark}
                          helperText={
                            formErrors?.remark?.type === "maxLength" &&
                            "Maximum 50 characters are allowed."
                          }
                          {...register("remark", {
                            maxLength: 50,
                            defaultValue: currentOrder.remark,
                          })}
                          error={!!formErrors?.remark}
                          inputProps={{ maxLength: 50 }}
                          onChange={(event) => validateText(event)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"installer"}
                          label={"Team"}
                          variant={"outlined"}
                          value={`${
                            currentOrder?.createdBy?.name
                          }-${currentOrder?.createdBy?.username.toUpperCase()}`}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"company"}
                          label={"Company"}
                          variant={"outlined"}
                          defaultValue={
                            currentOrder?.company
                              ? `${currentOrder?.company.name}-${currentOrder?.company.code}`
                              : ""
                          }
                          InputProps={{ disabled: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"customer-no"}
                          label={"Customer No."}
                          variant={"outlined"}
                          {...register("customerNo")}
                          disabled
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"ship-to-customer-name"}
                          label={"Ship-to Customer Name"}
                          variant={"outlined"}
                          {...register("shipToCustomerName")}
                          disabled
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"bill-to-customer-name"}
                          label={"Bill-to Customer Name"}
                          variant={"outlined"}
                          {...register("billToCustomerName")}
                          disabled
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"order-create-date"}
                          label={"Request Date"}
                          variant={"outlined"}
                          value={moment(currentOrder.createdAt || "").format(
                            "DD/MM/YYYY HH:mm:ss",
                          )}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"status"}
                          label={"Status"}
                          variant={"outlined"}
                          value={currentOrder.status || " "}
                          autoComplete={"off"}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={"form"}>
              <Grid item xs={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Typography variant={"h2"}>Project</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"project-id"}
                          label={"Project Manager"}
                          variant={"outlined"}
                          defaultValue={
                            currentOrder?.projectManager
                              ? `${currentOrder?.projectManager.userName}-${currentOrder?.projectManager.userId}`
                              : ""
                          }
                          InputProps={{ disabled: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"project-name"}
                          label={"Project Name"}
                          variant={"outlined"}
                          defaultValue={
                            currentOrder?.project
                              ? `${currentOrder?.project.projectName}-${currentOrder?.project.projectId}`
                              : ""
                          }
                          InputProps={{ disabled: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
