import { FormProvider, useForm } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import { PageWrapper } from "./components/PageWrapper";
import React, { useMemo, useState } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { FnbSupplierCardComponent } from "./components/FnbSupplierComponent";
import { useSupplierListApi } from "./api/useSupplierListApi";
import { fnbSupplierSchema } from "./schema/fnb-supplier-schema";
import { FnbSupplierItemComponent } from "./components/FnbSupplierItemComponent";

const Actions = ({ history, saveAction }) => {
  return (
    <>
      <Button
        className={"btn-cancel"}
        onClick={() => history.push("/app/fnb-supplier-list")}
      >
        Back
      </Button>
      <Button className={"primary"} onClick={saveAction} type="submit">
        Save
      </Button>
    </>
  );
};

export function SupplierForm({ supplierData, pageTitle, prerequisites }) {
  const [isLocalPageLoading, setLocalPageLoading] = useState(false);
  const history = useHistory();
  const vendorCode = useMemo(() => supplierData.vendorCode, [supplierData]);
  const { updateSupplier, createSupplier } = useSupplierListApi();

  // Forms
  const methods = useForm({
    values: supplierData,
    mode: "all",
    resolver: joiResolver(fnbSupplierSchema, { allowUnknown: true }),
  });

  const saveAction =
    (showNotify = true) =>
    (data) => {
      setLocalPageLoading(true);
      const saveActionApi = (data) => {
        if (vendorCode) return updateSupplier(vendorCode, data);
        return createSupplier(data);
      };

      return saveActionApi(data)
        .then((result) => result.data)
        .then((supplier) => supplier.vendorCode)
        .then((vendorCode) => {
          if (showNotify === true)
            Swal.fire({
              icon: "success",
              title: "Success",
              text: `Supplier ${vendorCode} has been updated.`,
              willClose(popup) {
                history.push(`/app/fnb-supplier-list/${vendorCode}`);
                history.go(0);
              },
            });
          return vendorCode;
        })
        .finally(() => {
          setLocalPageLoading(false);
        });
    };
  const formSaveHandler = methods.handleSubmit(saveAction(true));
  return (
    <>
      <FormProvider {...methods}>
        <PageWrapper
          title={pageTitle}
          action={
            <>
              <Actions
                supplierData={supplierData}
                history={history}
                saveAction={formSaveHandler}
              />
            </>
          }
        >
          <Grid item xs={12} disabled={isLocalPageLoading}>
            <FnbSupplierCardComponent prerequisites={prerequisites} />
          </Grid>
          <Grid item xs={12} disabled={isLocalPageLoading}>
            <FnbSupplierItemComponent prerequisites={prerequisites} />
          </Grid>
        </PageWrapper>
      </FormProvider>
    </>
  );
}
