import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  setStore,
  useStoreDispatch,
  useStoreState,
} from "../../../../context/StoreContext";
import { useUserState } from "../../../../context/UserContext";
import { Controller } from "react-hook-form";
import { useHttpRequest } from "../../../../hooks/useHttpRequest";
import { KeyboardArrowDown } from "@mui/icons-material";
import { validateText } from "../../../../helper/validate-textfield";
import AutocompleteController from "../../../../components/AutocompleteController";

export default function OrderPageHeader(props) {
  const storeDispatcher = useStoreDispatch();
  const { store, storeList } = useStoreState();
  const { userInfo } = useUserState();
  const { sendRequest } = useHttpRequest();

  const [projectManagerList, setProjectManagerList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectName, setProjectName] = useState(null);
  const [projectNameDisabled, setProjectNameDisabled] = useState(true);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    sendRequest(
      "/v1/user/get-list-by-role/option_project_manager",
      "GET",
      {},
    ).then((response) => {
      let list = [];
      if (response.data?.users) {
        list = response.data.users.map((u) => ({
          _id: u._id,
          userId: u.username,
          userName: u.name,
          projectGroup: u.projectGroup,
        }));
      }
      setProjectManagerList(list);
      return list;
    });
  }, []);

  useEffect(() => {
    sendRequest(`/v1/company?status=true`, "GET", {}).then((response) => {
      const list = response.data || [];

      if (userInfo && userInfo.companies.length > 0) {
        setCompanyList(
          list.filter((item) => userInfo.companies.includes(item.code)),
        );
        if (userInfo.companies.length === 1) {
          props?.setValue(
            "company",
            list.find((item) => userInfo.companies[0] == item.code),
          );
        }
      } else {
        setCompanyList(list);
      }
    });
  }, [userInfo]);

  function getPkrHelperText() {
    if (props?.formErrors?.pkrNo?.type === "required") {
      return "Document No. cannot be blank.";
    }

    if (props?.formErrors?.pkrNo?.type === "maxLength") {
      return "Maximum is 8 character.";
    }

    if (props?.formErrors?.pkrNo?.type === "pattern") {
      return "Document No. may contain only numbers (0-9).";
    }
  }

  async function onChangeProjectManager(data) {
    // clear selected project name
    props?.setValue("project", null);
    setProjectName(null);
    // disable if project manager not selected
    setProjectNameDisabled(!data);
    let projects = [];
    if (data && data.projectGroup) {
      // load related projects
      const result = await sendRequest(
        `/v1/projects/get-by-group/${data.projectGroup}`,
        "GET",
        {},
      );
      projects = result.data?.projects || [];
    } else {
      if (!props?.getValues("project")) props?.clearErrors("project");
    }
    setProjectList(projects); // set related project list
  }

  return (
    <>
      <Grid item xs={12}>
        <Grid container className={"form"}>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Typography variant={"h2"}>General</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Autocomplete
                      options={storeList}
                      getOptionLabel={(store) => `${store.name}-${store.code}`}
                      defaultValue={() => {
                        return storeList
                          .filter((i) => i.code === store.code)
                          .pop();
                      }}
                      value={store}
                      onChange={(e, newValue) =>
                        setStore(storeDispatcher, newValue)
                      }
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Store"}
                          variant={"outlined"}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      id={"pkrNo"}
                      label={"Document No. (Enter '00' when not relevent)"}
                      variant={"outlined"}
                      helperText={getPkrHelperText()}
                      {...props?.register("pkrNo", {
                        required: true,
                        maxLength: 8,
                        pattern: /^[0-9]+$/,
                      })}
                      error={!!props?.formErrors?.pkrNo}
                      autoComplete={"off"}
                      autoFocus={true}
                      inputProps={{
                        maxLength: 8,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            WSHB-
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      id={"remark"}
                      label={"Remark"}
                      variant={"outlined"}
                      {...props?.register("remark", { maxLength: 50 })}
                      autoComplete={"off"}
                      error={!!props?.formErrors?.remark}
                      helperText={
                        props?.formErrors?.remark?.type === "maxLength" &&
                        "Maximum 50 characters are allowed."
                      }
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => validateText(event)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <TextField
                      id={"installer"}
                      label={"Team"}
                      variant={"outlined"}
                      value={`${
                        userInfo.name
                      }-${userInfo.username.toUpperCase()}`}
                      disabled
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <AutocompleteController
                      name={"company"}
                      label={"Company"}
                      options={companyList}
                      getOptionLabel={(option) => {
                        return option && `${option.name}-${option.code}`;
                      }}
                      disabled={companyList.length === 1}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={"form"}>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Typography variant={"h2"}>Project</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Controller
                  name={`projectManager`}
                  control={props?.control}
                  render={(props) => (
                    <Autocomplete
                      id={"project-manager"}
                      options={projectManagerList}
                      getOptionLabel={(pm) => `${pm.userName}-${pm.userId}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Project Manager"}
                          variant={"outlined"}
                          error={!!props?.formState?.errors?.projectManager}
                          helperText={
                            props?.formState?.errors?.projectManager?.message
                          }
                          autoComplete="off"
                        />
                      )}
                      onChange={(event, data) => {
                        props?.field.onChange(data);
                        onChangeProjectManager(data);
                      }}
                      popupIcon={<KeyboardArrowDown />}
                    />
                  )}
                  onChange={(data) => {
                    props?.setValue(data);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Controller
                  name={`project`}
                  control={props?.control}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (!value && props?.getValues("projectManager"))
                          return "Project Name cannot be blank when Project Manager is provided.";
                        return true;
                      },
                    },
                  }}
                  render={(renderProps) => (
                    <Autocomplete
                      id={"project"}
                      options={projectList}
                      getOptionLabel={(project) =>
                        `${project.projectName}-${project.projectId}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Project Name"}
                          variant={"outlined"}
                          error={!!renderProps?.formState?.errors?.project}
                          helperText={
                            renderProps?.formState?.errors?.project?.message
                          }
                          autoComplete="off"
                        />
                      )}
                      onChange={(event, data) => {
                        renderProps?.field.onChange(data);
                        setProjectName(data);
                      }}
                      value={projectName}
                      popupIcon={<KeyboardArrowDown />}
                      disabled={projectNameDisabled}
                    />
                  )}
                  onChange={(data) => {
                    props?.setValue(data);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
