import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import DataTable from "react-data-table-component";
import { convertThisToCurrency } from "../../../helper";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { uniqBy } from "lodash/array";
import EditIcon from "@mui/icons-material/Settings";
import { SupplierItemEdit } from "./SupplierItemEdit";
import AutocompleteController from "../../../components/AutocompleteController";

const StoreSwitches = ({ storeOptions, arrayIndex, defaultStoreValues }) => {
  const formFieldIndex = useMemo(
    () => `items.${arrayIndex}.stores`,
    [arrayIndex],
  );
  const {
    formState: { errors: formErrors },
    setValue,
    watch,
  } = useFormContext();
  const selectedStores = watch(formFieldIndex, []);
  const isStoreSelected = (store) =>
    (selectedStores || []).some(
      (tickedS) => tickedS.storeCode === store.storeCode,
    );

  const onChangeStoreSwitch = (store) => (event, checked) => {
    const newStoreList = uniqBy(
      checked
        ? [...selectedStores, store]
        : [...selectedStores].filter((s) => store.storeCode !== s.storeCode),
      (s) => s.storeCode,
    );
    setValue(formFieldIndex, newStoreList);
    setValue(`items.${arrayIndex}.isChanged`, true);
  };

  const errors = useMemo(
    () => formErrors?.["items"]?.[arrayIndex] || {},
    [arrayIndex, formErrors],
  );

  return (
    <AutocompleteController
      name={`items.${arrayIndex}.stores`}
      required
      options={storeOptions}
      defaultValue={defaultStoreValues}
      keyName="storeCode"
      multiple
      getOptionLabel={(option) =>
        `${option.storeName.toUpperCase()}-${option.storeCode}`
      }
    />
    // <FormControl
    //   component="fieldset"
    //   variant="standard"
    //   error={!!errors["stores"]}
    // >
    //   <FormGroup sx={{ flexDirection: "row" }}>
    //     {storeOptions.map((store) => (
    //       <FormControlLabel
    //         key={store.storeCode}
    //         control={
    //           <Switch
    //             checked={isStoreSelected(store)}
    //             onChange={onChangeStoreSwitch(store)}
    //             name={store.storeCode}
    //           />
    //         }
    //         label={`${store.storeName.toUpperCase()}-${store.storeCode}`}
    //       />
    //     ))}
    //   </FormGroup>
    //   {errors?.["stores"] && (
    //     <FormHelperText>
    //       {errors?.stores && "Store cannot be blank."}
    //     </FormHelperText>
    //   )}
    // </FormControl>
  );
};

export function FnbSupplierItemComponent({ prerequisites }) {
  const storeOptions = useMemo(() => prerequisites.storesList, [prerequisites]);
  const { setValue, watch, getValues } = useFormContext();
  const [textFilter, setTextFilter] = useState("");

  // Items Data
  const itemList = watch("items", []);
  const filteredList = useMemo(() => {
    return itemList
      .filter((i) => !i.isDeleted)
      .filter(
        (i) =>
          [i.sku, i.description]
            .join("|")
            .toLowerCase()
            .search(textFilter.toLowerCase()) !== -1,
      );
  }, [itemList, textFilter]);

  // Edit Item Popup
  const [currentItem, setCurrentItem] = useState(false);
  const openItemEditDialog = useCallback(
    (row) => {
      setCurrentItem(row);
    },
    [setCurrentItem],
  );
  const closeDialog = useCallback(() => setCurrentItem(false), []);

  const onChangeItem = useCallback(
    (item) => {
      const arrayIndex = itemList.findIndex((i) => i._id === item._id);
      if (arrayIndex === -1) {
        itemList.push(item);
      } else {
        itemList[arrayIndex] = item;
      }
      setValue("items", itemList.slice());
      setValue(`items.${arrayIndex}.stores`, itemList[arrayIndex].stores);
      closeDialog();
    },
    [closeDialog, itemList, setValue],
  );

  const handleDeleteItem = useCallback(
    (itemId) => {
      const item = itemList.find((i) => i._id === itemId);
      if (item) {
        item.isDeleted = true;
        setValue("items", itemList.slice());
      }
    },
    [itemList, setValue],
  );

  const tableCols = useMemo(
    () => [
      {
        width: "80px",
        cell: (row, index) => {
          return (
            <>
              <IconButton
                onClick={(e) => openItemEditDialog(row)}
                title={"Edit"}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleDeleteItem(row._id);
                }}
                title={"Delete"}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      },
      {
        name: "SKU",
        width: "90px",
        selector: (row) => row.sku,
        sortable: true,
      },
      {
        name: "Description",
        width: "450px",
        selector: (row) => row.description,
        wrap: true,
      },
      {
        name: "UOM",
        width: "70px",
        selector: (row) => row.uom,
        sortable: true,
      },
      {
        name: "Unit Price",
        width: "100px",
        right: true,
        selector: (row) => convertThisToCurrency(row.price.toFixed(2)),
        sortable: true,
      },
      {
        name: "Stores",
        minWidth: "350px",
        cell: (row, index) => {
          const fieldIndex = itemList.findIndex((item) => item._id === row._id);
          return (
            <StoreSwitches
              storeOptions={storeOptions}
              arrayIndex={fieldIndex}
              defaultStoreValues={getValues(`items`)?.[index]?.stores ?? []}
            />
          );
        },
      },
    ],
    [
      handleDeleteItem,
      itemList,
      openItemEditDialog,
      storeOptions,
      getValues(`items`),
    ],
  );
  const dataTableConfig = useMemo(
    () => ({
      columns: tableCols,
      sortField: "createdAt",
      sortOrder: "desc",
      page: 1,
      pageSize: 10,
    }),
    [tableCols],
  );

  return (
    <>
      <Grid container className={"form"}>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} className={"sub-action"}>
              <Typography variant={"h2"}>Supplier Items</Typography>
              <Button
                onClick={() =>
                  openItemEditDialog({ _id: "" + Date.now(), isNew: true })
                }
              >
                <AddIcon />
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <TextField
                variant={"outlined"}
                value={textFilter}
                onChange={(e) => setTextFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} className={"table"}>
              <DataTable
                fixedHeader={true}
                persistTableHead={true}
                columns={dataTableConfig.columns}
                data={filteredList}
                pagination
                paginationDefaultPage={dataTableConfig.page}
                paginationPerPage={dataTableConfig.pageSize}
                paginationTotalRows={itemList.length}
              />
            </Grid>
          </Grid>
        </Grid>
        {currentItem !== false && (
          <SupplierItemEdit
            onClose={closeDialog}
            onChange={onChangeItem}
            storeOptions={storeOptions}
            defaultValues={currentItem}
          />
        )}
      </Grid>
    </>
  );
}
