import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Joi from "joi";
import { uniqBy } from "lodash/array";
import AutocompleteController from "../../../components/AutocompleteController";

const filter = createFilterOptions();
const validateEmail = Joi.string().email({ tlds: false }).required();

export function FnbSupplierCardComponent({ prerequisites }) {
  const storeOptions = useMemo(() => prerequisites.storesList, [prerequisites]);
  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const supplierId = watch("_id");
  const isNewSupplier = useMemo(() => supplierId === undefined, [supplierId]);
  const existingEmailList = watch("contactEmails");
  const selectedStores = watch("stores");

  const isStoreSelected = useCallback(
    (store) => selectedStores.some((s) => s.storeName === store.storeName),
    [selectedStores],
  );
  const onChangeStoreSwitch = (store) => (e, checked) => {
    if (checked) {
      setValue(
        "stores",
        uniqBy([...selectedStores, store], (s) => s.storeCode),
      );
    } else {
      setValue(
        "stores",
        uniqBy(
          [...selectedStores].filter((s) => store.storeCode !== s.storeCode),
          (s) => s.storeCode,
        ),
      );
    }
  };

  return (
    <>
      <Grid container className={"form"}>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <Typography variant={"h2"}>General</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <TextField
                label={"Vendor Code"}
                variant={"outlined"}
                helperText={
                  errors?.vendorCode && "Supplier Code cannot be blank."
                }
                error={!!errors?.vendorCode}
                disabled={!isNewSupplier}
                {...register("vendorCode")}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <TextField
                label={"Name"}
                variant={"outlined"}
                helperText={errors?.name && "Supplier Name cannot be blanl."}
                error={!!errors?.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label={"Address"}
                variant={"outlined"}
                {...register("address")}
                helperText={
                  errors?.address && "Supplier Address cannot be blank."
                }
                error={!!errors["address"]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <TextField
                variant={"outlined"}
                label={"Contact Name"}
                helperText={
                  errors?.contactName && "Contact Name cannot be blank."
                }
                error={!!errors?.contactName}
                {...register("contactName")}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <TextField
                variant={"outlined"}
                label={"Contact No."}
                helperText={
                  errors?.contactNumber && "Contact No. cannot be blank."
                }
                error={!!errors?.contactNumber}
                {...register("contactNumber")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                options={existingEmailList}
                filterSelectedOptions
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = existingEmailList.some(
                    (existEmail) => inputValue === existEmail,
                  );
                  if (
                    inputValue !== "" &&
                    !isExisting &&
                    !validateEmail.validate(inputValue).error
                  ) {
                    filtered.push({
                      value: inputValue,
                      label: `${inputValue}`,
                    });
                  }

                  return filtered;
                }}
                onChange={(event, newValue) => {
                  const newSelectedOptions = newValue || [];
                  setValue(
                    "contactEmails",
                    newSelectedOptions.map((i) =>
                      typeof i === "string" ? i : i.value,
                    ),
                  );
                }}
                ChipProps={{ deleteIcon: <Clear /> }}
                renderInput={(params) => (
                  <TextField
                    label={"Emails"}
                    {...params}
                    placeholder="Enter here.."
                    helperText={
                      errors?.contactEmails && "Emails cannot be blank."
                    }
                    error={!!errors?.contactEmails}
                  />
                )}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                value={existingEmailList}
                freeSolo
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4} xl={3}>
              <FormControl
                className="form-control"
                component="fieldset"
                variant="outlined"
                error={!!errors["stores"]}
              >
                <FormLabel component="legend">Stores</FormLabel>
                <FormGroup sx={{ flexDirection: "row" }}>
                  {storeOptions.map((store) => (
                    <FormControlLabel
                      key={store.storeCode}
                      control={
                        <Switch
                          checked={isStoreSelected(store)}
                          onChange={onChangeStoreSwitch(store)}
                          name={store.storeCode}
                        />
                      }
                      label={`${store.storeName.toUpperCase()}-${store.storeCode}`}
                    />
                  ))}
                </FormGroup>
                {errors?.stores && (
                  <FormHelperText>
                    {errors?.stores && "Store cannot be blank."}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <AutocompleteController
                name={"stores"}
                required
                label={"Stores"}
                options={storeOptions}
                defaultValue={[]}
                keyName="storeCode"
                multiple
                getOptionLabel={(option) =>
                  `${option.storeName.toUpperCase()}-${option.storeCode}`
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
