import React, { useEffect, useState } from "react";
import { Drawer, List } from "@mui/material";
import {
  ArrowBack as BackIcon,
  ChevronRight as BulletIcon,
  Code as MenuIcon,
  Settings as SettingIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeActions } from "../../data/privileges.enum";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";

const structure = [
  {
    id: "material",
    label: "Material",
    icon: <MenuIcon />,
    roles: [
      "admin",
      "wh_user",
      "installer",
      "installer_jr",
      "supervisor",
      "supervisor_proj",
      "buyer",
    ],
    type: "group",
    children: [
      {
        id: "material_request",
        label: "Material Request",
        link: "/app/material-request/list",
        icon: <BulletIcon />,
        pages: [
          { link: "/app/material-request/new" },
          { link: "/app/material-request/card" },
        ],
        type: "module",
      },
      {
        id: "material",
        label: "Material",
        link: "/app/material/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/material/new" }, { link: "/app/material/card" }],
        type: "module",
      },
    ],
  },
  {
    id: "bracket",
    label: "Bracket",
    icon: <MenuIcon />,
    roles: [
      "admin",
      "welder",
      "welder_assistant",
      "welder_qc",
      "welder_manager",
    ],
    type: "group",
    children: [
      {
        id: "bracket_request",
        label: "Bracket Request",
        link: "/app/bracket-request/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/bracket-request/card" }],
        type: "module",
      },
      {
        id: "bracket_batch",
        label: "Bracket A/B Batch",
        link: "/app/bracket-batch/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/bracket-batch/card" }],
        type: "module",
      },
      {
        id: "bracket",
        label: "Bracket",
        link: "/app/bracket/list",
        icon: <BulletIcon />,
        pages: [
          { link: "/app/bracket/card" },
          { link: "/app/bracket/quality-check" },
        ],
        type: "module",
      },
    ],
  },
  {
    id: "customer",
    label: "Customer",
    icon: <MenuIcon />,
    roles: ["admin", "sales"],
    type: "group",
    children: [
      {
        id: "customer_existing_aircon",
        label: "Customer Existing Aircon",
        link: "/app/customer-existing-aircon/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/customer-existing-aircon/card" }],
        type: "module",
      },
    ],
  },
  {
    id: "sub_con",
    label: "Claim",
    icon: <MenuIcon />,
    roles: ["admin", "service_claim_team", "service_claim_approval"],
    type: "group",
    children: [
      {
        id: "service_claim",
        label: "Service Claim",
        link: "/app/service-claim/list",
        icon: <BulletIcon />,
        pages: [
          { link: "/app/service-claim/card" },
          { link: "/app/service-claim/edit" },
        ],
        type: "module",
      },
      {
        id: "manage_service_claim_matrix",
        label: "Service Claim Matrix",
        link: "/app/service-claim-matrix/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/service-claim-matrix/card" }],
        type: "module",
      },
      {
        id: "service_claim_report",
        label: "Service Claim Report",
        link: "/app/service-claim-report/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/service-claim-report/card" }],
        type: "module",
      },
      {
        id: "installation_claim",
        label: "Installation Claim",
        link: "/app/installation-claim/list",
        icon: <BulletIcon />,
        pages: [
          { link: "/app/installation-claim/card" },
          { link: "/app/installation-claim/edit" },
        ],
        type: "module",
      },
      {
        id: "manage_installation_claim_matrix",
        label: "Installation Claim Matrix",
        link: "/app/installation-claim-matrix/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/installation-claim-matrix/card" }],
        type: "module",
      },
      {
        id: "installation_claim_report",
        label: "Installation Claim Report",
        link: "/app/installation-claim-report/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/installation-claim-report/card" }],
        type: "module",
      },
    ],
  },
  {
    id: "project",
    label: "Project (SP)",
    icon: <MenuIcon />,
    type: "group",
    children: [
      {
        id: "project_request",
        label: "Project Request",
        link: "/app/project-request/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/project-request/card" }],
        type: "module",
      },
      {
        id: "project",
        label: "Project",
        link: "/app/project/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/project/card" }],
        type: "module",
      },
    ],
  },
  // {
  //   id: "helpdesk",
  //   label: "Helpdesk",
  //   icon: <MenuIcon />,
  //   type: 'group',
  //   children: [
  //     {
  //       id: "task_tracking",
  //       label: "Task Tracking",
  //       link: "/app/task-tracking/list",
  //       icon: <BulletIcon />,
  //       type: 'module',
  //       pages: [{ link: "/app/task-tracking/card" }]
  //     }
  //   ]
  // },
  {
    id: "fnb",
    label: "F&B",
    icon: <MenuIcon />,
    type: "group",
    children: [
      {
        id: "fnb_event_booking",
        label: "F&B Event Booking",
        link: "/app/fnb-event-booking/list",
        icon: <BulletIcon />,
        pages: [
          { link: "/app/fnb-event-booking/card" },
          { link: "/app/fnb-event-booking/calendar" },
        ],
        type: "module",
      },
      {
        id: "fnb_event_menu",
        label: "F&B Event Menu",
        link: "/app/fnb-event-menu/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-event-menu/card" }],
        type: "module",
      },
      {
        id: "fnb_food",
        label: "F&B Dish",
        link: "/app/fnb-food/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-food/card" }],
        type: "module",
      },
      {
        id: "fnb_store",
        label: "F&B Store",
        link: "/app/fnb-store/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-store/card" }],
        type: "module",
      },
      {
        id: "fnb_order",
        label: "F&B Redemption",
        link: "/app/fnb-preset",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-food/card" }],
        type: "module",
      },
      {
        id: "fnb_kitchen_dashboard",
        label: "F&B Kitchen Dashboard",
        link: "/app/fnb-kitchen-dashboard",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-kitchen-dashboard" }],
        type: "module",
      },
      {
        id: "fnb_order",
        label: "F&B Sales Order",
        link: "/app/fnb-order/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-order/list" }],
        type: "module",
      },
      {
        id: "fnb_store_event",
        label: "F&B Store Event",
        link: "/app/fnb-store-event/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-store-event/card" }],
        type: "module",
      },
      {
        id: "fnb_report",
        label: "F&B Report",
        link: "/app/fnb-report",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-report" }],
        type: "module",
      },
      {
        id: "fnb_voucher_setup",
        label: "F&B Voucher Setup",
        link: "/app/fnb-voucher-setup",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-voucher-setup" }],
        type: "module",
      },
      {
        id: "fnb_supplier_order",
        label: "F&B Purchase Order",
        link: "/app/fnb-supplier-order",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-supplier-order" }],
        type: "module",
      },
      {
        id: "fnb_supplier",
        label: "F&B Supplier",
        link: "/app/fnb-supplier-list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/fnb-supplier-list" }],
        type: "module",
      },
    ],
  },
  {
    id: "machine",
    label: "Machine",
    icon: <MenuIcon />,
    type: "group",
    children: [
      {
        id: "machine_company",
        label: "Machine Company",
        link: "/app/machine-company/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/machine-company/card" }],
        type: "module",
      },
      {
        id: "machine_store",
        label: "Machine Store",
        link: "/app/machine-store/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/machine-store/card" }],
        type: "module",
      },
      {
        id: "machine_service_job",
        label: "Machine Service Job",
        link: "/app/machine-service-job/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/machine-service-job/card" }],
        type: "module",
      },
    ],
  },
  {
    id: "aircon_subscription",
    label: "Aircon Sub.",
    icon: <MenuIcon />,
    type: "group",
    children: [
      {
        id: "aircon_subscription_matrix",
        label: "Aircon Sub. Matrix",
        link: "/app/aircon-subscription-matrix/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/aircon-subscription-matrix/card" }],
        type: "module",
      },
      {
        id: "aircon_subscription_attribute",
        label: "Aircon Sub. Attributes",
        link: "/app/aircon-subscription-attribute",
        icon: <BulletIcon />,
        pages: [{ link: "/app/aircon-subscription-attribute" }],
        type: "module",
      },
      {
        id: "aircon_subscription",
        label: "Aircon Sub.",
        link: "/app/aircon-subscription/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/aircon-subscription/list" }],
        type: "module",
      },
    ],
  },
  {
    id: "group_buy",
    label: "Group Buy",
    icon: <MenuIcon />,
    type: "group",
    children: [
      {
        id: "group_buy_calendar",
        label: "Group Buy Calendar",
        link: "/app/group-buy-calendar/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/group-buy-calendar/card" }],
        type: "module",
      },
      {
        id: "group_buy_attributes",
        label: "Group Buy Attributes",
        link: "/app/group-buy-attribute",
        icon: <BulletIcon />,
        pages: [{ link: "/app/group-buy-attribute" }],
        type: "module",
      },
      {
        id: "group_buy_booking",
        label: "Group Buy Booking",
        link: "/app/group-buy-booking/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/group-buy-booking/card" }],
        type: "module",
      },
    ],
  },
  {
    id: "settings",
    label: "System",
    icon: <SettingIcon />,
    roles: ["admin", "sales"],
    type: "group",
    children: [
      {
        id: "user",
        label: "User",
        link: "/app/user/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/user/card" }],
        type: "module",
      },
      {
        id: "role",
        label: "Role",
        link: "/app/role/list",
        icon: <BulletIcon />,
        pages: [{ link: "/app/role/card" }],
        type: "module",
      },
      {
        id: "company",
        label: "Company",
        link: "/app/company",
        icon: <BulletIcon />,
        pages: [],
        type: "module",
      },
      {
        id: "activity_log",
        label: "Activity Log",
        link: "/app/activity-log",
        icon: <BulletIcon />,
        pages: [],
        type: "module",
      },
    ],
  },
];

function Sidebar({ location }) {
  const { auth } = useRoleAuthorization();
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  const { userInfo } = useUserState();

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  function toggleOpenClose(link) {
    if (location.pathname && link) {
      const links = link.children?.map((c) => ({
        link: c.link,
        pages: c.pages,
      }));
      const subLinks = links.map((l) => l.pages);
      let includeInLinks =
        links.findIndex((l) => location.pathname.includes(l.link)) !== -1;
      let includeInSubLinks = false;
      if (subLinks) {
        includeInSubLinks =
          subLinks.findIndex((sl) =>
            sl.find((e) => location.pathname.includes(e.link)),
          ) !== -1;
      }
      return includeInLinks || includeInSubLinks;
    }
    return false;
  }

  return (
    <Drawer
      ModalProps={{ onBackdropClick: () => toggleSidebar(layoutDispatch) }}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames("tool-bar", classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      {/* <div className={classes.toolbar} /> */}
      <div className={"sub-action"}>
        <BackIcon
          onClick={() => toggleSidebar(layoutDispatch)}
          classes={{
            root: classNames(classes.headerIcon, classes.headerIconCollapse),
          }}
        />
      </div>
      <List className={classes.sidebarList}>
        {structure.map(
          (link) =>
            ((link.type == "group" &&
              auth.checkGroupPrivilege(link.id, PrivilegeActions.view_list)) ||
              (link.type == "module" &&
                auth.checkModulePrivilege(
                  link.id,
                  PrivilegeActions.view_list,
                ))) && (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                open={toggleOpenClose(link)}
                {...link}
              />
            ),
        )}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
