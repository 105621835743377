import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  IconButton,
  Autocomplete,
} from "@mui/material";
import Swal from "sweetalert2";
import "./style/index.css";
import { Controller, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { validateText } from "../../helper/validate-textfield";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DataTable from "react-data-table-component";
import { slotTableColumnConfig } from "./data-table/slot-table-column-config";
import { Add } from "@mui/icons-material";
import dayjs from "dayjs";
import * as moment from "moment";
import _ from "lodash";
import { hasCommonElement } from "../../helper/hasCommonElement";
import { Clear, KeyboardArrowDown } from "@mui/icons-material";
import createUniqueId from "../../helper/createUniqueId";
import { isNullOrEmptyString } from "../../helper";

const CalendarCard = (props) => {
  // user info & permissions
  const { auth } = useRoleAuthorization();

  // handle loading & http
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();
  const [currentCalendarItem, setCurrentCalendarItem] = useState(); // current Calendar object for edit
  const [message, setMessage] = useState(""); // handle swal message
  const [enableSwitchValue, setEnableSwitchValue] = useState(true); // enable switch => default true
  const [slotList, setSlotList] = useState([]);
  const [stores, setStores] = useState([]);
  const [storeSelected, setStoreSelected] = useState();
  const [groupBuyEvents, setGroupBuyEvents] = useState([]);

  const { id } = useParams(); // url param => item id when editing
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setValue,
    control,
    reset,
    getValues,
    watch,
  } = useForm({ mode: "all", defaultValues: currentCalendarItem });

  // form submit
  const handleSubmitForm = async (data) => {
    try {
      if (checkInvalidSlotList(slotList)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Slot List cannot be blank, missing data or duplicate slot time",
        });
        return;
      }
      const payload = {
        name: data.name.trim().toUpperCase(),
        minDayInterval: Number(data.minDayInterval),
        maxDay: Number(data.maxDay),
        enabled: data.enabled,
        isWaitingList: data.isWaitingList,
        slotList: mapSlotList(slotList),
        store: data.store,
      };
      if (id) {
        await sendRequest(`/v1/group-buy-calendar/${id}`, "PUT", payload);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Group buy calendar ${payload.name} has been updated.`,
        });
      } else {
        const result = await sendRequest(
          `/v1/group-buy-calendar`,
          "POST",
          payload,
        );
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `New group buy calendar ${payload.name} has been created.`,
          willClose: navigateToList,
        });
      }

      return;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message || "An unknown error occurred.",
      });
      return;
    }
  };

  // navigate back to material list
  const navigateToList = () => {
    if (!id) window.location.href = "#/app/group-buy-calendar/list";
  };

  const onRemoveRow = (i) => {
    const rows = [...slotList];
    rows.splice(i, 1);
    setSlotList([...rows]);
  };

  const onChangeSlotItem = (fieldName, i, value) => {
    const copySlotList = [...slotList];
    copySlotList[i][fieldName] = value;
    setSlotList(copySlotList);
  };

  const onChangeSlotGroupItem = (fieldName, i, value) => {
    const copySlotList = slotList.map((item, index) => {
      if (index === i || (item.parentId && item.parentId === slotList[i].id)) {
        return {
          ...item,
          slotGroup: {
            ...item.slotGroup,
            [fieldName]: value,
          },
        };
      }
      return item;
    });

    setSlotList(copySlotList);
  };

  const onCopyRow = (i) => {
    const copySlotList = [
      ...slotList.slice(0, i),
      { ...slotList[i] },
      ...slotList.slice(i),
    ];

    if (!copySlotList[i].id) {
      copySlotList[i].id = createUniqueId();
    }
    copySlotList[i + 1].id = createUniqueId();
    copySlotList[i + 1].parentId = copySlotList[i].id;

    setSlotList(copySlotList);
  };

  const createNewRow = () => {
    setSlotList([
      ...slotList,
      {
        id: createUniqueId(),
        capacity: 10,
        date: [null, null],
        time: [null, null],
        slotGroup: groupBuyEvents.reduce((acc, item) => {
          acc[item.id] = false;
          return acc;
        }, {}),
        dayOfWeek: [],
      },
    ]);
  };

  const checkInvalidSlotRow = (row) => {
    if (
      isNullOrEmptyString(row.capacity) ||
      !row.dayOfWeek.length ||
      !row.date[0] ||
      !row.date[1] ||
      !row.time[0] ||
      !row.time[1] ||
      dayjs(row.date[0]).isAfter(row.date[1], "days") ||
      dayjs(row.time[0]).isAfter(row.time[1], "hour") ||
      dayjs(row.time[0]).isAfter(row.time[1], "minute")
    ) {
      return true;
    }
    return false;
  };

  const mapSlotList = (slotList = []) => {
    return slotList.map((slot) => {
      return {
        slotId: convertDateTimeToID(slot),
        startDate: slot.date[0].toDate(),
        endDate: slot.date[1].toDate(),
        startTime: moment(slot.time[0].toDate()).format("HH:mm"),
        endTime: moment(slot.time[1].toDate()).format("HH:mm"),
        capacity: slot.capacity,
        dayOfWeek: slot.dayOfWeek,
        slotGroup: slot.slotGroup,
      };
    });
  };

  const checkInvalidSlotList = (slotList = []) => {
    // Check Slot List is empty
    if (!slotList.length) {
      return true;
    }
    // Check slot data
    const invalidSlot = slotList.find((slot) => checkInvalidSlotRow(slot));
    if (invalidSlot) {
      return true;
    }

    const mapSlot = mapSlotList(slotList);
    for (let i = 0; i < mapSlot.length; i++) {
      for (let j = 0; j < mapSlot.length; j++) {
        if (i !== j) {
          if (
            mapSlot[i].slotId === mapSlot[j].slotId &&
            hasCommonElement(mapSlot[i].dayOfWeek, mapSlot[j].dayOfWeek)
          ) {
            return true;
          }
        }
      }
    }
  };

  const checkInvalidLastSlot = (slotList) => {
    const lastSlotList = [...slotList].pop();
    if (slotList.length && checkInvalidSlotRow(lastSlotList)) {
      return true;
    }
    return false;
  };

  const convertDateTimeToID = (slot) => {
    return (
      moment(slot.date[0].toDate()).format("YYYYMMDD") +
      moment(slot.date[1].toDate()).format("YYYYMMDD") +
      moment(slot.time[0].toDate()).format("HHmm") +
      moment(slot.time[1].toDate()).format("HHmm")
    );
  };

  const revertMapSlotList = (slotList = []) => {
    return slotList.map((slot) => {
      console.log(slot);
      return {
        slotId: slot.slotId,
        date: [dayjs(slot.startDate), dayjs(slot.endDate)],
        time: [dayjs(slot.startTime, "HH:mm"), dayjs(slot.endTime, "HH:mm")],
        capacity: slot.capacity,
        dayOfWeek: slot.dayOfWeek,
        slotGroup: groupBuyEvents.reduce((acc, item) => {
          acc[item.id] = slot?.slotGroup?.[item.id] || false;
          return acc;
        }, {}),
      };
    });
  };

  const fetchStore = async () => {
    try {
      const { data } = await sendRequest(`/v1/group-buy-store`, "GET");
      setStores(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
      return;
    }
  };

  const columns = slotTableColumnConfig({
    onRemoveRow,
    onChangeSlotItem,
    onCopyRow,
    checkInvalidSlotRow,
    onChangeSlotGroupItem,
    groupBuyEvents,
  });

  useEffect(() => {
    if (
      id &&
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.group_buy_calendar,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }
    fetchStore();
    fetchEvents();
  }, []);

  useEffect(() => {
    document.title = "New - Group Buy Calendar Card";
    if (currentCalendarItem) {
      reset({
        name: currentCalendarItem.name,
        minDayInterval: currentCalendarItem.minDayInterval,
        maxDay: currentCalendarItem.maxDay,
        enabled: currentCalendarItem.enabled,
        store: currentCalendarItem.store,
        isWaitingList: currentCalendarItem.isWaitingList || false,
      });
      setEnableSwitchValue(currentCalendarItem.enabled);
      setSlotList(revertMapSlotList(currentCalendarItem.slotList));
      setStoreSelected(currentCalendarItem.store);
      document.title = `${currentCalendarItem.calendarNo} - Group Buy Calendar Card`;
    }
  }, [currentCalendarItem, groupBuyEvents]);

  const fetchCalendar = async (id) => {
    try {
      const { data } = await sendRequest(`/v1/group-buy-calendar/` + id, "GET");
      setCurrentCalendarItem(data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unknown error occurred.",
      });
      return;
    }
  };

  const fetchEvents = async () => {
    const { data } = await sendRequest(`/v1/group-buy-event`, "GET");
    setGroupBuyEvents(data);
  };

  useEffect(() => {
    if (id) {
      fetchCalendar(id);
    }
  }, [id]);

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant={"h1"}>
              Group Buy Calendar Card{" "}
              {currentCalendarItem
                ? ` - ${currentCalendarItem.calendarNo}`
                : ` - New`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
            <Button onClick={() => props.history.push("/app/group-buy-calendar/list")}>
              Cancel
            </Button>
            {((!id &&
              auth.checkModulePrivilege(
                PrivilegeModules.group_buy_calendar,
                PrivilegeActions.add,
              )) ||
              (id &&
                auth.checkModulePrivilege(
                  PrivilegeModules.group_buy_calendar,
                  PrivilegeActions.edit,
                ))) && (
              <Button
                className={"primary"}
                type={"submit"}
                disabled={
                  Object.keys(formErrors).length !== 0 ||
                  checkInvalidSlotList(slotList)
                }
              >
                Save
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container className={"form"}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Typography variant={"h2"}>General</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"name"}
                          label={"Calendar Name"}
                          type={"text"}
                          variant={"outlined"}
                          defaultValue={""}
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Calendar Name cannot be blank.",
                            },
                            maxLength: {
                              value: 30,
                              message: "Maximum 30 characters are allowed.",
                            },
                          })}
                          autoComplete={"off"}
                          error={!!formErrors?.name}
                          helperText={formErrors?.name?.message}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"minDayInterval"}
                          label={"Min. Day"}
                          variant={"outlined"}
                          type={"number"}
                          defaultValue={0}
                          {...register("minDayInterval", {
                            required: {
                              value: true,
                              message: "Min. Day cannot be blank.",
                            },
                            pattern: {
                              value: /^\d*\.?\d*$/,
                              message:
                                "Min. Day format is invalid - Only allow (0-9).",
                            },
                          })}
                          autoComplete={"off"}
                          error={!!formErrors?.minDayInterval}
                          helperText={formErrors?.minDayInterval?.message}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <TextField
                          id={"maxDay"}
                          label={"Maximum Day"}
                          variant={"outlined"}
                          type={"number"}
                          defaultValue={14}
                          {...register("maxDay", {
                            required: {
                              value: true,
                              message: "Max. Day cannot be blank.",
                            },
                            pattern: {
                              value: /^\d*\.?\d*$/,
                              message:
                                "Max. Day format is invalid - Only allow (0-9).",
                            },
                          })}
                          autoComplete={"off"}
                          error={!!formErrors?.maxDay}
                          helperText={formErrors?.maxDay?.message}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Controller
                          name={`store`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Store cannot be blank.",
                            },
                          }}
                          render={(props) => (
                            <Autocomplete
                              id={"store"}
                              options={stores}
                              getOptionLabel={(item) => item.name.toUpperCase()}
                              value={storeSelected || null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"Store"}
                                  variant={"outlined"}
                                  error={!!props?.formState?.errors?.store}
                                  helperText={
                                    props?.formState?.errors?.store?.message
                                  }
                                  autoComplete="off"
                                />
                              )}
                              onChange={(event, data) => {
                                setStoreSelected(data);
                                props?.field.onChange(data);
                              }}
                              popupIcon={<KeyboardArrowDown />}
                              ChipProps={{ deleteIcon: <Clear /> }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <FormControlLabel
                          labelPlacement="start"
                          control={
                            <Switch
                              {...register("enabled")}
                              checked={enableSwitchValue}
                              onChange={(e) =>
                                setEnableSwitchValue(e.target.checked)
                              }
                            />
                          }
                          label="Enable"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <FormControlLabel
                          labelPlacement="start"
                          control={
                            <Switch
                              {...register("isWaitingList")}
                              checked={getValues("isWaitingList") || false}
                              onChange={(e) => {
                                watch("isWaitingList");
                                setValue("isWaitingList", e.target.checked);
                              }}
                            />
                          }
                          label="Waiting List"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container className={"form"}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} className={"sub-action"}>
                    <Typography variant={"h2"}>Slots</Typography>
                  </Grid>
                  <Grid item xs={12} className={"table"}>
                    <DataTable
                      fixedHeader={true}
                      persistTableHead={true}
                      columns={columns}
                      data={slotList}
                    />
                  </Grid>
                  <Grid item xs={12} className={"table-action"}>
                    <IconButton
                      onClick={createNewRow}
                      disabled={checkInvalidLastSlot(slotList)}
                    >
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CalendarCard;
