import { Autocomplete, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { Clear, KeyboardArrowDown } from "@mui/icons-material";

const AutocompleteController = ({
  type,
  name,
  label,
  defaultValue,
  required,
  rules,
  InputProps,
  disabled,
  options,
  hiddenLabel,
  multiple,
  keyName,
  value,
  ...props
}) => {
  const { control } = useFormContext();

  const handleMultipleOption = (options, values) => {
    const filterd = options.filter(
      (item) => !values.some((val) => val[keyName] === item[keyName]),
    );
    return filterd;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...props}
          options={
            !multiple
              ? options
              : handleMultipleOption(options, value ?? field.value)
          }
          value={value ?? field.value}
          onChange={(e, newValue) => field.onChange(newValue)}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={!hiddenLabel ? label : undefined}
              variant={"outlined"}
              hiddenLabel={hiddenLabel}
              error={!!error}
              helperText={error?.message}
            />
          )}
          popupIcon={<KeyboardArrowDown />}
          multiple={multiple}
          ChipProps={multiple && { deleteIcon: <Clear /> }}
        />
      )}
      rules={{ required: required && `${label} cannot be blank.`, ...rules }}
    />
  );
};

export default AutocompleteController;
