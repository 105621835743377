import moment from "moment";
import { Link } from "react-router-dom";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";
import { isAwarded } from "../helper/pr-config";
import { IconButton, Link as MuiLink } from "@mui/material";
import { Settings as EditIcon } from "@mui/icons-material";
export const projectRequestTableConfig = (
  onClickReferenceNo,
  viewDetailAllowed,
  onClickProjectId,
  handlePRClick,
  isOpenPRDrawerValid,
) => {
  const showActualValue = (value, status, isPercentage = false) =>
    isAwarded(status)
      ? isPercentage
        ? `${value?.toFixed(2) ?? 0.0}%`
        : `${convertThisToCurrency(value?.toFixed(2) ?? 0.0)}`
      : "-";

  const columns = [
    {
      name: "Reference No.",
      width: "170px",
      cell: (row) => {
        if (viewDetailAllowed) {
          return isOpenPRDrawerValid({
            step: row.step,
            status: row.status,
            version: row.version,
          }) ? (
            <MuiLink onClick={() => handlePRClick(row)}>
              {row.referenceNo}
            </MuiLink>
          ) : (
            <Link to={`card/${row._id}`}>{row.referenceNo}</Link>
          );
        }
        return <>{row.referenceNo}</>;
      },
      sortable: true,
      sortField: "referenceNo",
    },
    {
      name: "",
      width: "50px",
      center: true,
      cell: (row) => {
        if (viewDetailAllowed) {
          return (
            isOpenPRDrawerValid({
              step: row.step,
              status: row.status,
              version: row.version,
            }) && (
              <Link to={`card/${row._id}`}>
                <IconButton>
                  <EditIcon
                    style={{
                      fontSize: 14,
                    }}
                  />
                </IconButton>
              </Link>
            )
          );
        }
        return <>{row.referenceNo}</>;
      },
      sortable: true,
      sortField: "referenceNo",
    },
    {
      name: "Customer",
      width: "300px",
      selector: (row) => row.customer,
      wrap: true,
      sortable: true,
      sortField: "customer",
    },
    {
      name: "Status",
      width: "90px",
      selector: (row) => row.status,
      sortable: true,
      sortField: "status",
    },
    {
      name: "Step",
      width: "60px",
      right: true,
      selector: (row) => row.step,
    },
    {
      name: "Tendency Due Date",
      width: "130px",
      selector: (row) =>
        row.tendencyDueDate
          ? moment(row.tendencyDueDate).format("DD/MM/YYYY")
          : "",
      wrap: true,
      sortable: true,
      sortField: "tendencyDueDate",
    },
    {
      name: "Project Manager",
      width: "250px",
      selector: (row) =>
        `${row.projectManager?.name}-${row.projectManager?.username}`,
      wrap: true,
      sortable: true,
      sortField: "projectManager",
    },
    {
      name: "Project Address",
      width: "300px",
      selector: (row) => `${row.location}`,
      wrap: true,
      sortable: true,
      sortField: "location",
    },
    {
      name: "Company",
      width: "90px",
      selector: (row) => row.company.code,
      wrap: true,
      sortable: true,
      sortField: "companyName",
    },
    {
      name: "Total Cost (P)",
      width: "130px",
      selector: (row) =>
        `${convertThisToCurrency(row.totalCost?.toFixed(2) ?? 0.0)}`,
      sortable: true,
      right: true,
      sortField: "totalCost",
    },
    {
      name: "Contract Value (P)",
      width: "150px",
      selector: (row) =>
        `${convertThisToCurrency(row.contractValue?.toFixed(2) ?? 0.0)}`,
      sortable: true,
      right: true,
      sortField: "contractValue",
    },
    {
      name: "Profit Margin (P)",
      width: "150px",
      selector: (row) => `${row.profitMargin?.toFixed(2) ?? 0.0}%`,
      sortable: true,
      right: true,
      sortField: "profitMargin",
    },
    {
      name: "Total Cost (A)",
      width: "130px",
      selector: (row) => showActualValue(row.totalCostA, row.status),
      sortable: true,
      right: true,
      sortField: "totalCostA",
    },
    {
      name: "Contract Value (A)",
      width: "150px",
      selector: (row) => showActualValue(row.contractValueA, row.status),
      sortable: true,
      right: true,
      sortField: "contractValueA",
    },
    {
      name: "Profit Margin (A)",
      width: "150px",
      selector: (row) => showActualValue(row.profitMarginA, row.status, true),
      sortable: true,
      right: true,
      sortField: "profitMarginA",
    },
    {
      name: "Total Received",
      width: "130px",
      selector: (row) => showActualValue(row.totalPayment, row.status),
      sortable: true,
      right: true,
      sortField: "totalPayment",
    },
    {
      name: "Contract Balance",
      width: "140px",
      selector: (row) => showActualValue(row.outstandingBalance, row.status),
      sortable: true,
      right: true,
      sortField: "outstandingBalance",
    },
    {
      name: "Project ID",
      width: "200px",
      cell: (row) =>
        row.project ? (
          <Link
            to={"#"}
            onClick={(e) => onClickProjectId(row.project?.projectId)}
          >
            {`${row.project?.projectId}`}
          </Link>
        ) : (
          "-"
        ),
      sortable: true,
      sortField: "projectId",
    },
    {
      name: "Project Name",
      width: "300px",
      cell: (row) => (row.project ? `${row.project?.projectName}` : "-"),
      sortable: true,
      sortField: "projectName",
    },
    {
      name: "UEN No.",
      width: "120px",
      selector: (row) => row.uen,
      sortable: true,
      sortField: "uen",
    },
    {
      name: "Awarded Date",
      width: "150px",
      wrap: true,
      selector: (row) =>
        row.awardedDate
          ? moment(row.awardedDate).format("DD/MM/YYYY HH:mm:ss")
          : "-",
      sortable: true,
      sortField: "awardedDate",
    },
    {
      name: "Progress",
      width: "90px",
      selector: (row) => (row.progress ? `${row.progress}%` : "-"),
      sortable: true,
      center: true,
      sortField: "progress",
    },
    {
      name: "Internal Reference No.",
      width: "200px",
      selector: (row) => row.internalReferenceNo,
      sortable: true,
      wrap: true,
      sortField: "internalReferenceNo",
    },
    {
      name: "Project Title",
      width: "1000px",
      selector: (row) => row.projectTitle,
      sortable: true,
      wrap: true,
      sortField: "projectTitle",
    },
  ];
  return columns;
};
